import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "AVG Internet Security",
  "path": "/Quick_Installation/Live_Video/Troubleshooting/AVG/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Set an Exception for your IP Camera",
  "image": "./QI_SearchThumb_LiveVideo_AVG.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo_AVG.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='AVG' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/Problembehebung/AVG/' locationFR='/fr/Quick_Installation/Live_Video/Troubleshooting/AVG/' crumbLabel="AVG" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "set-an-exception-rule-in-your-antivirus-protection-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-an-exception-rule-in-your-antivirus-protection-software",
        "aria-label": "set an exception rule in your antivirus protection software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set an exception rule in your antivirus protection software.`}</h2>
    <p>{`In some cases, the AVG virus scanner may block the video stream from your camera. Actually, it blocks the ActiveX and Quicktime plugin from receiving the video stream. In this case, you need to set an exception in AVG so that it no longer blocks the video stream.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "760px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/3c051/AVG_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjUlEQVQozy3IaW/SYAAA4P6HAcapA0rpQYFebw/arhdHCxQo5VjCQBEYsMVsyxazy7lLnM4PxhjnZvwTi1n8pn/NzC15Pj2QZlqO2xZlW1TuCJlCIqUQKTlJq5Lq3KVcoDmDl/I0Z6RojZfyFKvrVpWXshAnyJJq04JF8xYjZClg4KkMSmZQUk6zZooxkrSOJ5U0MEhaJSiF4s0kvZjmjHhChBBYgMMgFgVxWEBjIhwGSUriVZWTVFoWqYwIFlV+UY1FARLlMURCYRGOgliURxERQnyFWSmBFRft6EhLZUclfuKiXQ3radmttrnVwHsa3tfVVV+YVJCWhnQ0deqDsRurKRB8bpV+Tfxfr7ArB//i+LfrzdsN4ltp4bM5/XMy/Xvy7EKPfM02bzecm1H0kxW7ynd+bxduhpEjHcL385mfTeVHi3jvgJkHrmvgygMf6thZgbvymMsqflzAZgVwXWe/19DDPHZug0uPvfTQbQsKlj10PCKnk0C9FbLL4XYr0m6Fiu6cU6ZHQ2o4CNrlgOOyowHxvBfKl+cr3rzvzzcaT3JFKLr0IrG2iU3Wkf74UdnHukN0+WWgVA84NbI3IpYHAbsadBtodwB3+nN2LVhpLrS7T9vdUM6FyqdvhY/H9OwAP915vLcePtyKHL8mdjbjKQVPKlhSjlMqd7IHLo6o2UH63T55tkue7qbO3+Aba1CpP7ZXVrn6El1rpmvNpOuTFZ9xfRqYaUanGJ0CJpF3CbuK25WEXSXsasKpkkUPMxzINGtFp2kaFcus5ixPlgosY/DA4oQcK+Q4IQfEHI6LGCY8QAU0zt9BeSiMMDDGwxiIxNl7YYS5F/kvjDBRlHsQZ2OEgNMazmgIKf0DItK8sm/aJKkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/e4706/AVG_01.avif 230w", "/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/d1af7/AVG_01.avif 460w", "/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/83f21/AVG_01.avif 760w"],
              "sizes": "(max-width: 760px) 100vw, 760px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/a0b58/AVG_01.webp 230w", "/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/bc10c/AVG_01.webp 460w", "/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/9046c/AVG_01.webp 760w"],
              "sizes": "(max-width: 760px) 100vw, 760px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/81c8e/AVG_01.png 230w", "/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/08a84/AVG_01.png 460w", "/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/3c051/AVG_01.png 760w"],
              "sizes": "(max-width: 760px) 100vw, 760px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6efbd9bf9fe2a9eca2a145c2ce6aee10/3c051/AVG_01.png",
              "alt": "AVG Internet Security",
              "title": "AVG Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please start the AVG control panel.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "761px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/8c857/AVG_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwklEQVQoz33RXW/TVgDGcd9MMAqFBJw0SWPHzjnHr/Hr8TmOneCEOk1LkqZJ2qSlpRWtCowBGkPbBJq2TjCkpV1TkIBqX2MTE3e74XtNuZm0G6Tf/SP9H6a2EMfNNVJepGGThk2HLCDVR6ov62UvWMR+jP245Fy3cd10I6hSC9eUUkCDplduMKRcJWHTInWL1G16w/TqshEgvYz0soVvmG7dcGqKUTFwVICuUqqQ8hJUfB5gNqswQKRAIFAkCFAJ+CKPNdMnURWHFavqGwEltQqpVxH0EiyS9VDSwlRWTaalS0mRgcOqvb9s7i2hUSQOQ7zfcu/ehLcqaDsKvu5GjwfS7evyVoQPWoLmFYDHFZ1kWkqw6GJCYMBJY+Pj095fj+Dpovq2Nfr72+GHJ9qb9vwkevjPyx8+neROI/Go0f7zK6O2kJs3kRbkBftaRp1JCIx62Aj/6NOzVeWwQcY9932HnK2QV6vyi9g7W3HfdeSXsfxT7LxvZ7FVFL25vD5zpXDhspBIQYZd72uP78EHe+nhgF1t8zujws4o1W2xK23r/h3r3h670sp0O9L+thHEul7BdBHTRl4w5/IakxtsqweP4N6Dwvou2+ii4S5Y37kWd5LLPWX3rrJzkFzusQsdYW0rI3kCcCUtTOf0mYQ4DdafjMnkhTP+WX31PPvjN8Vfvge/PlOfPQFupLqRhmtFu4qff2cfH6bNMkS0KNNzF/PnLnHTYN7apj7c1Nc3QH9N6A2E1b7YH8jdwfRqhUoKRZqvdwf6aDMNbF50eOBmOTM9r0+XAcAS9CToqYiqiGgSVRGVEVVKoWJUpkqhhIgMvVRGEaAnaUGON6/OKbNXiwwPHR66HHTywP4f0fwPB2wO2IkUgoovIu/8LPfFhdyXszxz9Pvr45M34+PT344mnzE+nviVJaUUQplkOD3LGXnB+hf+v7tFGCilbQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/e4706/AVG_02.avif 230w", "/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/d1af7/AVG_02.avif 460w", "/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/a59a4/AVG_02.avif 761w"],
              "sizes": "(max-width: 761px) 100vw, 761px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/a0b58/AVG_02.webp 230w", "/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/bc10c/AVG_02.webp 460w", "/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/6e34c/AVG_02.webp 761w"],
              "sizes": "(max-width: 761px) 100vw, 761px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/81c8e/AVG_02.png 230w", "/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/08a84/AVG_02.png 460w", "/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/8c857/AVG_02.png 761w"],
              "sizes": "(max-width: 761px) 100vw, 761px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/09e6e01209f87deb04dcb5f3c0ab1c4d/8c857/AVG_02.png",
              "alt": "AVG Internet Security",
              "title": "AVG Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Go to Options (Optionen) and Advanced Settings (Erweiterte Einstellungen).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "805px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b1799dca875d44e1538110af13aac65d/c946b/AVG_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.1304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9UlEQVQozyXOaW/adgCAcb+q1G1KSRocDnMGH9h/X9j4Nra5bOzEEEIMhcTlalTaau3I1WTrlL7ZtM+wLzvRSb/3zwP5J2EvmGimb9phw+5Lqme3hk33otkZtb3I9Sey7gPOonmH4R2mtkPztqi4ptOH5EbQPb1U7AGj+Jwa0LKHURpWVY5xqVARylgdKbFwlkzlwBEC4Ay5kyWT6eoBjEKa93a0vO+MNvbg2tl5V8AkhreyeeblXuHlq+LPidLrFPHLfjmRRLNFNlNg03k6laPZugu1+qtB/Ft4+dkIFqoXC603x4Ri2IFq9GTNU4yeqLRlrXuMiQTQWp2h1QwlzVXN4MPN35AVzPvx9nT2uT28NoIFa0UlXKYYA7ANkm3QvFWlDbSqJA4rMAI4wQFcA86SmQK7vX+GmuEynH6UO1OxPeOdCW+PS5hEMgYnOATQaa5BMgZGqvtJFEZArd5ianYKoZAS9/XpGbL678fbfzuzP2vOWGhNeXtcxCQCaIBr4ECjGBOntB9lFM4CVrAp1oSzZLbA3T8+Q4r7dvT+r+Hqm+Jecc6Es8dlXGZqFic6gLN4sbk7Z4xEEj3K0aLS4UQnnQM/yt8hzb2MltvJ+u5ieWf315wVFVGJADoOdILWcUojgFYh5H0YP0JomrdIxkwhVK7M3399huTOzI0+dkYbf/Kpe7GROrP8sUAApYKLFKNRjI4SUgmtvfgpvfe6zPImCdS9/VIiiT48fYcY/YzUdqraGaUPcTkU9ZPZ6iG6uoni26v1YxTfRvFN7+w6jD5drR+ny4dZvFm++/Lw7R/IH/96vv5juHwaLp/OV78PF7eCGviDRTBY9vpz9zTu9edNb+qFc7MdcZLPiJ6odAWlWyEUqB9t3izuongbxdvJ/CZefUFJLZMHSJFBiky2QOdKbCYP8mU2nacOYPQwjR+miYMjPJWjIdX0UUKsAhUj66UKny9zOKXglPo/jFT4erPdO1d0z7BPvZOxZvqK7gpSixPs/wDewMbzbg2W7wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1799dca875d44e1538110af13aac65d/e4706/AVG_03.avif 230w", "/en/static/b1799dca875d44e1538110af13aac65d/d1af7/AVG_03.avif 460w", "/en/static/b1799dca875d44e1538110af13aac65d/621da/AVG_03.avif 805w"],
              "sizes": "(max-width: 805px) 100vw, 805px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b1799dca875d44e1538110af13aac65d/a0b58/AVG_03.webp 230w", "/en/static/b1799dca875d44e1538110af13aac65d/bc10c/AVG_03.webp 460w", "/en/static/b1799dca875d44e1538110af13aac65d/82ef0/AVG_03.webp 805w"],
              "sizes": "(max-width: 805px) 100vw, 805px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1799dca875d44e1538110af13aac65d/81c8e/AVG_03.png 230w", "/en/static/b1799dca875d44e1538110af13aac65d/08a84/AVG_03.png 460w", "/en/static/b1799dca875d44e1538110af13aac65d/c946b/AVG_03.png 805w"],
              "sizes": "(max-width: 805px) 100vw, 805px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b1799dca875d44e1538110af13aac65d/c946b/AVG_03.png",
              "alt": "AVG Internet Security",
              "title": "AVG Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Click on Exceptions (Ausnahmen) and add an exception for the affected plugins.`}</p>
    <p>{`The ActiveX plugin for the HD camera can be found in C:/Windows/SystemWOW64 (or System32) and is called SEWebClientPlus.ocx for the HD cameras (or DVM_IPCam2.ocx in C:\\Windows\\Downloaded Program Files for the VGA cameras). In case that the HD camera´s video is working but you cannot start the integrated media player - you will need an exception for the file SEPlayerPlus.exe that is also located in C:/Windows/SystemWOW64 (or System32). If you encounter any problems during the settings, please feel free to contact us any time during our office hours. We will try everything to solve the problem as fast as possible.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      